/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import MaterialPaper from '@mui/material/Paper';
import { CommonStyles } from '../../common/commonStyles.ts';

export type PaperProps = {
  children?: React.ReactNode;
  elevation?: number;
  isSquare?: boolean;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  style?: SerializedStyles;
  variant?: 'elevation' | 'outlined';
};

export const Paper: React.FC<PaperProps> = ({
  children,
  elevation = 1,
  isSquare = false,
  onBlur,
  onClick,
  onFocus,
  style,
  variant = 'elevation',
}) => (
  <MaterialPaper
    elevation={elevation}
    square={isSquare}
    variant={variant}
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
    sx={css({
      '&.MuiPaper-root': css(
        {
          ...CommonStyles.MaterialCubicTransitions,
          zIndex: elevation,
        },
        style,
      ),
    })}
  >
    {children}
  </MaterialPaper>
);
