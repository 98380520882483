/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

export interface IFocusChangeEvent {
  isFocused: boolean;
}

export interface IFocusStateProps {
  style?: SerializedStyles;
  children?: React.ReactNode;
  tabIndex?: number;
  tag?: 'div' | 'ul';
  isFocused?: boolean;
  onFocusChange?: (e: IFocusChangeEvent) => void;
}

export interface IFocusStateState {
  isFocused: boolean;
}

export interface IFocusable {
  isFocused?: boolean;
}

const styles = {
  base: css({
    position: 'relative',
    outline: 'none',
  }),
};

/**
 * HoC that monitors and maintains focus state.
 * @deprecated Please use standard Material UI components
 */
export class FocusState extends React.Component<
  IFocusStateProps,
  IFocusStateState
> {
  public state: IFocusStateState = {
    isFocused: false,
  };

  private element: HTMLElement | null;
  private elementRef = (el: HTMLElement) => {
    this.element = el;
  };

  public componentDidMount() {
    // If the component loads in a focused state
    // ensure the DOM element has received focus.
    if (this.props.isFocused) {
      this.focus();
    }
  }

  /**
   * Imperatively focus the component
   */
  public focus() {
    this.setState({ isFocused: true });
    if (this.element) {
      this.element.focus();
    }
  }

  public get isFocused() {
    return this.props.isFocused !== undefined
      ? this.props.isFocused
      : this.state.isFocused;
  }

  private get tabIndex() {
    const { tabIndex = 0 } = this.props;
    return tabIndex < 0 ? undefined : tabIndex;
  }

  public render() {
    const { tag = 'div', children } = this.props;
    const isFocused = this.isFocused;

    const elements = Array.isArray(children)
      ? children.map((el) => clone(el as React.ReactElement<any>, isFocused))
      : clone(children as React.ReactElement<any>, isFocused);

    return tag === 'ul' ? (
      <ul
        css={css(styles.base, this.props.style)}
        tabIndex={this.tabIndex}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={this.elementRef}
      >
        {elements}
      </ul>
    ) : (
      <div
        css={css(styles.base, this.props.style)}
        tabIndex={this.tabIndex}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={this.elementRef}
      >
        {elements}
      </div>
    );
  }

  private notifyParent() {
    this.props.onFocusChange?.({ isFocused: this.state.isFocused });
  }

  private handleFocus = () => this.setIsFocused(true);
  private handleBlur = () => this.setIsFocused(false);

  private setIsFocused = async (isFocused: boolean) => {
    setTimeout(() => {
      this.setState({ isFocused });
    }, 0);

    this.notifyParent();
  };
}

const clone = (element: React.ReactElement<any>, isFocused: boolean) =>
  React.cloneElement(element, { isFocused });
