/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Paper } from '../Paper/Paper.tsx';

export interface ICardProps {
  children?: React.ReactNode;
  borderColor?: number | string;
  backgroundColor?: number | string;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  padding?: number | string;
  margin?: number | string;
  elevation?: number;
  style?: SerializedStyles;
  childContainerStyle?: SerializedStyles;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: React.FocusEventHandler<object>;
  onBlur?: React.FocusEventHandler<object>;
}

/**
 * A Card like container of content.
 */
export const Card: React.FC<ICardProps> = ({
  children,
  width,
  height,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  backgroundColor = -0.02,
  padding,
  margin,
  style = {},
  childContainerStyle = {},
  elevation = 1,
  onClick,
  onFocus,
  onBlur,
}) => {
  const styles = {
    base: css({
      position: 'relative',
      border: `solid 1px ${color.format(-0.18)}`,
      borderRadius: 3,
      margin,
    }),
    inner: css({
      borderRadius: 3,
      backgroundColor: color.format(backgroundColor),
      boxSizing: 'border-box',
      width,
      height,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      padding,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      justifyContent: 'stretch',
    }),
  };

  return (
    <Paper
      style={css(styles.base, style)}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      elevation={elevation}
    >
      <div css={[styles.inner, childContainerStyle]}>{children}</div>
    </Paper>
  );
};
