/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { events } from '@seeeverything/ui.util/src/events.rx/index.ts';
import * as R from 'ramda';
import React from 'react';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import {
  IRenderListRowArgs,
  List,
  ListClickEvent,
  ListClickEventHandler,
  ListEdgeEvent,
  ListEdgeEventHandler,
  ListSelectionChangedEvent,
  ListSelectionChangedEventHandler,
} from '../../../List/index.ts';
import {
  SelectionEdge,
  SelectReason,
} from '../../../ListSelectionBehavior/types.ts';
import { SelectionListItem } from '../../../SelectionListItem/SelectionListItem.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
  SelectionListSize,
} from '../../types.ts';
import { EmptyMessage } from '../EmptyMessage.tsx';
import { SectionHeader } from '../SectionHeader/SectionHeader.tsx';

export interface IInnerListProps {
  index: number;
  items?: ISelectionListItem[];
  selectedId?: string | number;
  size?: SelectionListSize;
  emptyMessage?: string;
  isStateful?: boolean;
  isScrollable?: boolean;
  isDimmedSelectable?: boolean;
  tabIndex?: number;
  isFocused: boolean;
  keys$?: Observable<React.KeyboardEvent> | null;
  onSelectionChanged?: ListSelectionChangedEventHandler;
  onEdgeSelected?: ListEdgeEventHandler;
  onClick?: ListClickEventHandler;
}

export interface IInnerListState {
  keys$?: Observable<KeyboardEvent>;
}

/**
 * A list (or multi-column lists) of selection options.
 */
export class InnerList extends React.Component<IInnerListProps, object> {
  public static defaultProps = {
    size: 'LARGE',
  };
  private items: ISelectionListItem[] | undefined;
  private hasIcons = false;
  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IInnerListProps>();
  private keys$ = (this.props.keys$ || events.keydown$).pipe(
    takeUntil(this.unmounted$),
    filter(() => this.isFocused() === true),
  );

  private list: List;
  private listRef = (ref: List) => {
    this.list = ref;
  };

  private isFocused(rowArgs?: IRenderListRowArgs) {
    return this.props.isFocused === undefined && rowArgs
      ? rowArgs.isFocused
      : this.props.isFocused; // NB: Explicit isFocused on <List> overrides row args.
  }

  public UNSAFE_componentWillMount() {
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    props$.subscribe((props) => this.updateFlags(props));
    this.props$.next(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IInnerListProps) {
    this.props$.next(nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  /**
   * Assigns focus to the list (or specified column).
   */
  public focus() {
    if (this.list) {
      this.list.focus();
    }
  }

  /**
   * Determines whether the list contains a selection.
   */
  public hasSelection() {
    return this.list ? this.list.hasSelection() : false;
  }

  /**
   * Retrieves the selected item.
   */
  public selection() {
    return this.list ? this.list.selection() : undefined;
  }

  /**
   * Fires the [onEdgeSelected] event callback.
   */
  public fireEdge = (edge: SelectionEdge) =>
    this.list ? this.list.fireEdge(edge) : undefined;

  /**
   * Selects the item with the specified ID.
   */
  public selectId(id: string | number, reason: SelectReason) {
    this.list.selectId(id, reason);
  }

  private updateFlags = (props: IInnerListProps) => {
    if (this.items !== props.items) {
      this.items = props.items;
      this.hasIcons = props.items
        ? R.any((item) => item.icon !== undefined, props.items)
        : false;
    }
  };

  public render() {
    const {
      isScrollable,
      isDimmedSelectable = true,
      emptyMessage,
    } = this.props;
    const styles = {
      base: css({
        position: isScrollable ? 'absolute' : undefined,
        inset: isScrollable ? 0 : undefined,
        cursor: 'default',
      }),
    };

    const items = (this.props.items || []).map((item) => {
      const { type = 'ITEM' } = item;
      const isClickable = type === 'ITEM';
      let isSelectable = type === 'ITEM' ? item.isSelectable : false;
      if (item.isDimmed && !isDimmedSelectable) {
        isSelectable = false;
      }
      return {
        ...item,
        isSelectable,
        isClickable,
      };
    });

    const elList = items.length > 0 && (
      <List
        ref={this.listRef}
        items={items}
        renderRow={this.renderRow}
        selectedId={this.props.selectedId}
        isFocused={this.isFocused()}
        isScrollable={isScrollable}
        isStateful={this.props.isStateful}
        tabIndex={this.props.tabIndex}
        keys$={this.keys$}
        onSelectionChanged={this.handleSelectionChanged}
        onEdgeSelected={this.handleEdgeSelected}
        onClick={this.handleClick}
      />
    );

    const elEmpty = items.length === 0 && emptyMessage && (
      <EmptyMessage>{emptyMessage}</EmptyMessage>
    );

    return (
      <div css={styles.base}>
        {elList}
        {elEmpty}
      </div>
    );
  }

  private renderRow = (args: IRenderListRowArgs) => {
    const { type = 'ITEM' } = args.data as ISelectionListItem;
    switch (type) {
      case 'ITEM':
        return this.renderItem(args);
      case 'CONTENT':
        return this.renderContent(args);
      case 'SECTION':
        return this.renderSection(args);
      case 'DIVIDER':
        return this.renderDivider();
      default:
        throw new Error(`List item type '${type}' not supported.`);
    }
  };

  private renderItem(args: IRenderListRowArgs) {
    const isFocused = this.isFocused(args);
    const data = args.data as ISelectionListItem;
    const isSelected = args.isSelected;
    const hasChildren = Boolean(
      data.children && data.children.items.length > 0,
    );
    return (
      <SelectionListItem
        content={data.content as React.ReactNode}
        data={data}
        hasChildren={hasChildren}
        hasIcon={this.hasIcons}
        icon={data.icon}
        iconColor={data.iconColor}
        iconOffsetY={data.iconOffsetY}
        isDescriptionSelectable={data.isDescriptionSelectable}
        isDimmed={data.isDimmed}
        isEnabled={data.isEnabled}
        isFocused={isFocused}
        isHighlighted={data.isHighlighted}
        isHoverable={data.isHoverable}
        isLabelSelectable={data.isLabelSelectable}
        isSelected={isSelected}
        isSpinning={data.isSpinning}
        onExpandChildren={this.itemExpandChildrenHandler()}
        size={this.props.size}
        statusIcon={data.statusIcon}
        statusIconColor={data.statusIconColor}
      >
        {data.content as React.ReactNode}
      </SelectionListItem>
    );
  }

  private renderSection(args: IRenderListRowArgs) {
    const { content } = args.data as ISelectionListItem;
    const title = R.is(String, content)
      ? (content as string)
      : (content as IListItemLabel).text;
    return (
      <SectionHeader
        title={title}
        isFirst={args.isFirst}
        isLast={args.isLast}
      />
    );
  }

  private renderDivider() {
    return <div css={styles.divider} />;
  }

  private renderContent(args: IRenderListRowArgs) {
    const { content } = args.data as ISelectionListItem;

    return React.isValidElement(content)
      ? (content as React.ReactElement<any>)
      : undefined;
  }

  private handleSelectionChanged = (e: ListSelectionChangedEvent) => {
    const { onSelectionChanged } = this.props;
    if (onSelectionChanged) {
      onSelectionChanged(e);
    }
  };

  private handleEdgeSelected = (e: ListEdgeEvent) => {
    const { onEdgeSelected } = this.props;
    if (onEdgeSelected) {
      onEdgeSelected(e);
    }
  };

  private handleClick = (e: ListClickEvent) => {
    const { onClick } = this.props;
    const { isClickable, isEnabled } = e.item;
    if (onClick && isClickable && isEnabled !== false) {
      onClick(e);
    }
  };

  private itemExpandChildrenHandler = () => () => {
    // If the "children" chevron icon is clicked, alert listers
    // that and edge was selected (same as ARROW_RIGHT key).
    if (this.list) {
      this.list.fireEdge('RIGHT');
    }
  };
}

const styles = {
  divider: css({
    borderTop: `solid 1px ${color.format(-0.12)}`,
    marginTop: 5,
    marginBottom: 5,
    height: 1,
  }),
};
