/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MaterialTooltip from '@mui/material/Tooltip';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { is } from 'ramda';

type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export type TooltipProps = {
  arrow?: boolean;
  children: React.ReactElement;
  interactive?: boolean;
  open?: boolean;
  placement?: TooltipPlacement;
  theme?: 'DARK' | 'LIGHT';
  title?: string | React.ReactElement;
};

export const Tooltip: React.FC<TooltipProps> = ({
  arrow = false,
  children,
  interactive = false,
  open,
  placement,
  theme = 'DARK',
  title = '',
}) => {
  const styles = {
    interactive: css({
      pointerEvents: 'unset',
    }),
    dark: css({
      fontSize: 14,
      padding: '8px 12px',
      border: `solid 1px ${color.format(-0.1)}`,
      backgroundColor: color.create('#717171').alpha(0.98).hex(),
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    }),
    light: css({
      backgroundColor: 'WHITE',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      border: `solid 1px ${color.format(-0.1)}`,
      fontSize: 14,
    }),
  };

  return (
    <MaterialTooltip
      aria-label={is(String, title) ? title : undefined}
      arrow={arrow}
      disableInteractive={!interactive}
      open={open}
      placement={placement}
      title={title}
      slotProps={{
        popper: {
          sx: css({
            '&.MuiPopper-root': css(interactive && styles.interactive),
          }),
        },
        tooltip: {
          sx: css({
            '&.MuiTooltip-tooltip': css(
              theme === 'DARK' ? styles.dark : styles.light,
            ),
          }),
        },
      }}
    >
      {children}
    </MaterialTooltip>
  );
};
