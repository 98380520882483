/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Observable } from 'rxjs';
import {
  ISelection,
  ListSelectionBehavior,
  SelectReason,
  SelectionChangedEvent,
  SelectionEdge,
  SelectionEdgeEvent,
} from '../ListSelectionBehavior/index.ts';
import { ListInnerList } from './components/ListInnerList.tsx';
import {
  IListItem,
  IRenderListRow,
  ListClickEvent,
  ListClickEventHandler,
  ListEdgeEventHandler,
  ListSelectionChangedEventHandler,
} from './types.ts';

export interface IListProps {
  items: IListItem[];
  renderRow: IRenderListRow;
  selectedId?: number | string;
  scrollDuration?: number;
  isScrollable?: boolean;
  isStateful?: boolean;
  tabIndex?: number;
  isFocused?: boolean;
  keys$?: Observable<React.KeyboardEvent>;
  onClick?: ListClickEventHandler;
  onDoubleClick?: ListClickEventHandler;
  onSelectionChanged?: ListSelectionChangedEventHandler;
  onEdgeSelected?: ListEdgeEventHandler;
}

/**
 * A selectable, scrollable, virtualized list of items.
 */
export class List extends React.Component<IListProps, object> {
  public static defaultProps = {
    scrollDuration: 200,
    isScrollable: false,
  };

  private selectionBehavior: ListSelectionBehavior;
  private selectionBehaviorRef = (ref: ListSelectionBehavior) => {
    this.selectionBehavior = ref;
  };

  /**
   * Assigns focus to the list.
   */
  public focus() {
    if (this.selectionBehavior) {
      return this.selectionBehavior.focus();
    }
  }

  /**
   * Determines whether the given column contains a selection.
   */
  public hasSelection() {
    return Boolean(this.selection());
  }

  /**
   * Retrieves the selected item.
   */
  public selection(): ISelection | undefined {
    const { items = [], selectedId } = this.props;
    const index = items.findIndex((item) => {
      if (selectedId !== undefined && item.id === selectedId) {
        return true;
      }
      return false;
    });

    return index < 0 ? undefined : { index, item: items[index] };
  }

  /**
   * Selects the item with the specified ID.
   */
  public selectId(id: string | number, reason: SelectReason) {
    if (this.selectionBehavior) {
      this.selectionBehavior.selectId(id, reason);
    }
  }

  /**
   * Fires the [onEdgeSelected] event callback.
   */
  public fireEdge = (edge: SelectionEdge) => {
    if (this.selectionBehavior) {
      this.selectionBehavior.fireEdge(edge);
    }
  };

  public render() {
    const {
      selectedId,
      items = [],
      isScrollable = true,
      tabIndex,
      isFocused,
    } = this.props;

    // Fill parent when isScrollable (Absolute: 0), otherwise Relative.
    const styles = {
      base: css({
        position: isScrollable ? 'absolute' : 'relative',
        inset: isScrollable ? 0 : undefined,
        margin: 0,
        padding: 0,
      }),
      outer: css({
        position: isScrollable ? 'absolute' : 'relative',
        inset: isScrollable ? 0 : undefined,
      }),
    };

    return (
      <div css={styles.outer}>
        <ListSelectionBehavior
          ref={this.selectionBehaviorRef}
          items={items}
          selectedId={selectedId}
          isFocused={isFocused}
          isStateful={this.props.isStateful}
          tabIndex={tabIndex}
          keys$={this.props.keys$}
          onSelectionChanged={this.handleSelectionChanged}
          onEdgeSelected={this.handleEdgeSelected}
          style={styles.base}
        >
          <ListInnerList
            items={items}
            selectedId={selectedId}
            renderItem={this.props.renderRow}
            isFocused={this.props.isFocused}
            isScrollable={isScrollable}
            onClick={this.handleItemClick}
            onDoubleClick={this.handleItemDoubleClick}
          />
        </ListSelectionBehavior>
      </div>
    );
  }

  private handleSelectionChanged = (e: SelectionChangedEvent) => {
    const { onSelectionChanged } = this.props;
    if (onSelectionChanged) {
      onSelectionChanged(e);
    }
  };

  private handleEdgeSelected = (e: SelectionEdgeEvent) => {
    const { onEdgeSelected } = this.props;
    if (onEdgeSelected) {
      onEdgeSelected(e);
    }
  };

  private handleItemClick = (e: ListClickEvent) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  private handleItemDoubleClick = (e: ListClickEvent) => {
    const { onDoubleClick } = this.props;
    if (onDoubleClick) {
      onDoubleClick(e);
    }
  };
}
