/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { Button } from '../Button/Button.tsx';
import { IIcon, Icons } from '../Icon/index.ts';
import {
  IListItemLabel,
  ISelectionListIconColor,
  ISelectionListItem,
  SelectionListSize,
} from '../SelectionList/types.ts';
import { Spinner } from '../Spinner/index.ts';
import { Content } from './components/Content.tsx';
import { ListIcon } from './components/ListIcon.tsx';

const BG_COLOR_SELECTED = color.format(-0.1);
const BG_COLOR_SELECTED_FOCUSED = COLORS.BLUE;
const BG_COLOR_OVER = color.format(-0.08);

export interface ISelectionListItemProps {
  children?: React.ReactNode;
  content?: React.ReactNode | IListItemLabel;
  data?: ISelectionListItem;
  hasChildren?: boolean;
  hasIcon?: boolean;
  icon?: IIcon | string;
  iconColor?: ISelectionListIconColor;
  iconOffsetY?: number;
  isDescriptionSelectable?: boolean;
  isDimmed?: boolean;
  isEnabled?: boolean;
  isFocused?: boolean;
  isHighlighted?: boolean;
  isHoverable?: boolean;
  isLabelSelectable?: boolean;
  isSelected?: boolean;
  isSpinning?: boolean;
  maxWidth?: string | number;
  onExpandChildren?: () => void;
  size?: SelectionListSize;
  statusIcon?: IIcon;
  statusIconColor?: ISelectionListIconColor;
}

export interface ISelectionListItemState {
  isOver: boolean;
}

const DEFAULT_SIZE = 'LARGE';

/**
 * A single list item.
 */
export const SelectionListItem: React.FC<ISelectionListItemProps> = React.memo(
  function SelectionListItem(props) {
    const {
      content,
      data,
      hasChildren,
      hasIcon = true,
      icon,
      iconColor,
      iconOffsetY = 0,
      isDescriptionSelectable,
      isDimmed: propIsDimmed = false,
      isEnabled = true,
      isFocused = false,
      isHighlighted,
      isHoverable = true,
      isLabelSelectable,
      isSelected = false,
      isSpinning = false,
      maxWidth,
      onExpandChildren,
      size = DEFAULT_SIZE,
      statusIcon,
      statusIconColor,
    } = props;

    const [isOver, setIsOver] = React.useState(false);
    const isActive = isSelected && isFocused;
    const isDimmed = propIsDimmed && !isOver;
    const isLarge = size === 'LARGE';
    const paddingX = isLarge ? 14 : 10;
    const iconSize = isLarge ? 22 : 18;
    const isHoveredOrFocused =
      isEnabled && isHoverable && (isSelected || isOver);

    const mouseOverHandler = (toIsOver: boolean) => () => setIsOver(toIsOver);
    const expandChildrenHandler = () => () => {
      if (onExpandChildren && isSelected && hasChildren && isEnabled) {
        onExpandChildren();
      }
    };

    const paddingRight = (() => {
      if (isSpinning) return 8;
      if (hasChildren) return 4;
      if (statusIcon) return 8;
      return paddingX;
    })();

    const styles = {
      body: css({
        position: 'relative',
        flex: '1 1 auto',
        height: '100%',
        width: '100%',
        opacity: isEnabled ? 1 : 0.3,
        padding: `10px ${paddingRight}px 10px ${paddingX}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: 10,
        minHeight: isLarge ? 22 : 18,
      }),
      content: css({
        flex: '1 1 auto',
        opacity: isDimmed ? 0.4 : 1,
        transition: 'opacity 0.5s',
      }),
      button: css({
        boxSizing: 'border-box',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        backgroundColor: isHoveredOrFocused
          ? !isSelected && isOver
            ? BG_COLOR_OVER
            : isFocused
              ? BG_COLOR_SELECTED_FOCUSED
              : BG_COLOR_SELECTED
          : undefined,
      }),
    };

    const showHighlight = isHighlighted && !isActive && isEnabled && !isDimmed;

    const elIcon = hasIcon && icon && (
      <ListIcon
        icon={icon}
        fill={isActive ? iconColor?.selected : iconColor?.default}
        offsetY={iconOffsetY}
        showHighlight={showHighlight}
        isSelected={isActive}
        size={iconSize}
      />
    );

    const elStatusIcon = !isSpinning && statusIcon && (
      <ListIcon
        icon={statusIcon}
        style={css({ marginTop: iconOffsetY })}
        fill={isActive ? statusIconColor?.selected : statusIconColor?.default}
        offsetY={iconOffsetY}
        showHighlight={showHighlight}
        isSelected={isActive}
        size={iconSize}
      />
    );

    const elChildrenIcon = !isSpinning && hasChildren && (
      <ListIcon
        icon={Icons.chevronRight}
        style={css({ marginTop: iconOffsetY })}
        offsetY={iconOffsetY}
        showHighlight={showHighlight}
        isSelected={isActive}
        size={iconSize}
      />
    );

    const elSpinner = isSpinning && <Spinner size={22} />;

    return (
      <Button
        dataTest={data?.dataTest}
        style={styles.button}
        isEnabled={isEnabled}
        onMouseEnter={mouseOverHandler(true)}
        onMouseLeave={mouseOverHandler(false)}
        onDoubleClick={expandChildrenHandler()}
      >
        <div css={styles.body}>
          {elIcon}
          <Content
            style={styles.content}
            data={data}
            size={size}
            isEnabled={isEnabled}
            isSelected={isSelected}
            isFocused={isFocused}
            isActive={isActive}
            hasChildren={hasChildren}
            isLabelSelectable={isLabelSelectable}
            isDescriptionSelectable={isDescriptionSelectable}
            content={content}
            maxWidth={maxWidth}
          />
          {elStatusIcon}
          {elChildrenIcon}
          {elSpinner}
        </div>
      </Button>
    );
  },
);
